'use client';

import { useEffect } from 'react';

import { signOut } from '@/lib/auth/browser';
import { useRouter } from '@/lib/navigation';

export function SignOutInClient() {
  const router = useRouter();
  useEffect(() => {
    (async () => {
      await signOut();
      router.replace('/auth');
    })();
  }, [router]);

  return <>{null}</>;
}
