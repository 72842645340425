import { BigNumber } from 'bignumber.js';
BigNumber.config({ DECIMAL_PLACES: 19, EXPONENTIAL_AT: [-19, 20] });

const separators = ['.', ',', "'"] as const;

export class InvalidNumberInputError extends Error {}

export function parseNumberWithLocalizedSeparators({
  value,
  throwIfInvalid,
}: {
  value: BigNumber.Value;
  throwIfInvalid?: boolean;
}) {
  if (BigNumber.isBigNumber(value)) {
    return value;
  }

  if (typeof value === 'number') {
    return new BigNumber(value);
  }

  let cleaned = value.trim();
  for (const separator of separators) {
    cleaned = cleaned.replaceAll(separator, '.');
  }

  const parsed = new BigNumber(cleaned);
  if (throwIfInvalid && parsed.isNaN()) {
    throw new InvalidNumberInputError(
      `failed to parse "${value}" to a number using separators "${separators.join('')}"`,
    );
  }

  return parsed;
}
