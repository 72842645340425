import { BigNumber } from 'bignumber.js';

import { parseNumberWithLocalizedSeparators } from './parseNumberWithLocalizedSeparators';

export function parseNumber({
  value,
  throwIfInvalid,
}: {
  value: BigNumber.Value;
  throwIfInvalid?: boolean;
}): BigNumber {
  return parseNumberWithLocalizedSeparators({ value, throwIfInvalid });
}
